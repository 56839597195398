<template>
  <div class="row box box-body presence-calendar-container">
    <div class="col-xs-12" id="presence-calendar"/>
    <div id="presence-calendar-explanation">
      <div class="presence presence-default">
        <div></div>
        <span>Obecność</span>
      </div>
      <div class="presence presence-remote">
        <div></div>
        <span>Obecność (Praca zdalna)</span>
      </div>
      <div class="presence presence-absent">
        <div></div>
        <span>Nieobecność</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import DynamicBreadcrumb from '@/components/bundles/share/mixins/DynamicBreadcrumb.js'
import WebStorage from '@/components/share/WebStorage.js'
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'
import {Calendar} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import esLocale from '@fullcalendar/core/locales/pl.js'

const CALENDAR_START = 'start'
const CALENDAR_REFETCH = 'refetch'
export default {
  name: 'Presences',
  mixins: [
    DynamicBreadcrumb,
    ErrorNotify
  ],
  data () {
    return {
      presences: []
    }
  },
  computed: {
    user () {
      return WebStorage.getUser()
    }
  },
  mounted () {
    this.addDynamicBreadcrumbLabel('admin_user_details', this.user.forename + ' ' + this.user.surname)
    this.getPresences()
  },
  methods: {
    getPresences () {
      api.request('core', 'get', `/users/${this.user.uuid}/presences`)
        .then(({data}) => {
          this.presences = data.map(el => {
            el.allDay = true
            el.start = el.date
            el.backgroundColor = el.status === 'z' ? '#8d37d8' : '#3788d8'
            return el
          })
          this.initCalendar(CALENDAR_START)
        })
        .catch((e) => {
          this.errorNotify(e)
        })
    },
    initCalendar (job) {
      const calendarEl = document.getElementById('presence-calendar')

      const calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        locale: esLocale,
        events: this.presences,
        allDaySlot: false,
        businessHours: {
          daysOfWeek: [ 1, 2, 3, 4, 5 ],

          startTime: '00:00',
          endTime: '00:00',
        },
        slotEventOverlap: false,
        themeSystem: 'bootstrap4',
        displayEventTime: true,
        progressiveEventRendering: true,
        height: 'auto',
        eventClick: () => {
        },
        headerToolbar: {
          start: 'title',
          center: '',
          end: ''
        }
      })
      if (job === CALENDAR_START) {
        calendar.render()
      } else if (job === CALENDAR_REFETCH) {
        calendar.render()
        // calendar.refetchEvents()
      }
    }
  }
}
</script>

<style scoped>
.presence-calendar-container {
  padding: 25px;
}

#presence-calendar-explanation {
  clear: both;
}

.presence {
  display: flex;
  flex-direction: row;
}

.presence > * {
  margin: 4px;
}

.presence > div {
  border-radius: 3px;
  height: 20px;
  width: 5rem;
}

.presence-default > div {
  border: solid #3788d8 1px;
  background-color: #3788d8;
}

.presence-remote > div {
  border: solid #8d37d8 1px;
  background-color: #8d37d8;
}

.presence-absent > div {
  border: solid rgba(0, 0, 0, 0) 1px;
  background-color: rgba(0, 0, 0, 0);
}
</style>
